import * as ACTION_TYPES from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.customParameters, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_CUSTOM_PARAMETERS:
      return [].concat(action.payload);
    default:
      return state;
  }
}

export function customParametersActions(state = initialState.customParametersActions, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_CUSTOM_PARAMETERS_ACTIONS:
      return Object.assign({}, action.payload);

    default:
      return state;
  }
}

export function allEngagements(state = initialState.allEngagements, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_ALL_ENGAGEMENTS:
      return [].concat(action.payload);

    default:
      return state;
  }
}

export function allFormsEngagements(state = initialState.allFormsEngagements, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_ALL_FORMS_ENGAGEMENTS:
      return [].concat(action.payload);

    default:
      return state;
  }
}

export function allAppRatingsEngagements(state = initialState.allAppRatingsEngagements, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_ALL_APP_RATINGS_ENGAGEMENTS:
      return [].concat(action.payload);

    default:
      return state;
  }
}

export function engagementGroups(state = initialState.engagementGroups, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_ENGAGEMENT_GROUPS:
      return Object.assign({}, action.payload);

    default:
      return state;
  }
}


